/* Bootstrap + Icons */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* NgZorro */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";

/* Material */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
    height: 100%;
}

mat-dialog-actions {
    float: right
}

.chartContainer canvas {
    max-height: 250px;
    width: auto;
}

.chartContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ant-upload.ant-upload-select {
    width: 100%;
}

.ant-upload.ant-upload-select button {
    width: 100%;
    padding: 10px;
    height: 50px;
    margin-top: 0px !important;
}

.error-messages nz-alert {
    margin-top: 20px;
    margin-bottom: 20px
}

.ant-table-content {
    overflow: auto;
}

.ant-table-content::-webkit-scrollbar {
    width: 20px;
}

.ant-table-content::-webkit-scrollbar-track {
    background-color: #fafafa !important;
    color: transparent !important;
}

.ant-table-content::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #177ddc;
}

.cursor-pointer {
    cursor: pointer;
}

.lista nz-list-header {
    padding: 0px !important;
}

.ant-steps-icon i.anticon,
.ant-breadcrumb-link svg,
.ant-form-item-label>label .ant-form-item-tooltip i {
    vertical-align: 0;
}

.ant-pagination-item-link i,
.ant-dropdown-trigger>.anticon.anticon-down {
    vertical-align: 0.200em;
}

.crop span.ng-star-inserted {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
}

.crop span.anticon.anticon-down {
    vertical-align: 0.525rem;
}

.bg-danger {
    background-color: #ff3649e0 !important;
}

.bg-gray {
    background-color: #dbdbdbad
}

.text-gray {
    color: #8d8d8d
}

@media screen and (max-width: 960px) {
    mat-dialog-actions {
        float: initial;
        justify-content: center;
    }

    mat-dialog-actions button {
        margin: 10px 0px !important;
    }
}